import React from "react";
import Layout from "../components/layout";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import SvgIcons from "../components/svgIcons/svgIcons";

const Catalog = () => {
  const data = useStaticQuery(graphql`
    query Catalog {
      allStrapiCategory {
        nodes {
          images {
            url
            localFile {
              url
              childImageSharp {
                gatsbyImageData(formats: AUTO)
              }
            }
          }
          type
          slug
          strapi_id
        }
      }
    }
  `);



  return (
    <Layout>
      <div className="container">
        <div className="content_header text-center mt-60">
          <h1>Каталог DENDOR</h1>
          <div className="p20">
            <p>
              АО «ЭНЕРГИЯ» поставляет надежную трубопроводную арматуру DENDOR во
              все регионы России. В каталоге DENDOR вы можете ознакомиться с
              ассортиментом и особенностями предлагаемой нами продукции.
            </p>
            <div className="download-catalog">
              <div className="download-catalog-icon">
                <SvgIcons
                  width="32"
                  height="32"
                  name="pdf2"
                  className="icon_svg price"
                />
              </div>
              <div className="download-catalog-text">
                <a href="http://static.dendor.ru/pdf/catalog.pdf">
                  {" "}
                  Скачать каталог в формате pdf
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="catalog mt-60">
          {data.allStrapiCategory.nodes.map((element) => {

            const { images, slug, type, strapi_id } = element;

            let img = getImage(images.localFile.childImageSharp);
            return (
              <div key={strapi_id} className="catalog__item">
                <div className="catalog__body">
                  <a href={`/${slug}`}>
                    <GatsbyImage placeholder="#fff" image={img} alt={type} />
                  </a>
                </div>
                <div className="catalog__header">
                  <a href={`/${slug}`}> {type}</a>
                </div>
              </div>
            );
          })}
        </div>

        <div className="mt-30 text-catalog">
          <p className="mb-30">
            В каталоге запорной регулирующей арматуры компании DENDOR, вы можете
            ознакомиться с ассортиментом и особенностями предлагаемой нами
            продукции.
          </p>

          <p className="mb-30">
            В нашем каталоге подробно описаны технические характеристики,
            размеры, конструкции и материалы следующих видов товара:
          </p>
          <ul className="mb-30">
            <li>затворы поворотные дисковые;</li>
            <li>шаровые краны промышленного применения;</li>
            <li>фильтры (например, фильтр сетчатый с магнитной вставкой);</li>
            <li>
              обратные клапаны (например, клапан обратный шаровой фланцевый,
              клапан обратный двухстворчатый межфланцевый);
            </li>
            <li>задвижки с обрезиненным клином;</li>
            <li>соленоидные клапаны;</li>
            <li>резиновые компенсаторы / вибровставки / гибкие вставки;</li>
            <li>и другую трубопроводную арматуру.</li>
          </ul>

          <p className="mb-30">
            Качество производимых изделий контролируется на каждом этапе
            производства и подтверждено сертификатами соответствия.
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default Catalog;
